import { Controller } from 'react-hook-form';
import React, { ReactElement, useEffect, useState } from 'react';
import AutoComplete from 'antd/lib/auto-complete';

interface AutocompleteItemProps {
  name: string;
  icon?: ReactElement | false;
  disabled?: boolean;
  initialValue?: string;
  control: any;
  placeholder?: string;
  allowClear?: boolean;
  options?: Array<{ id; name }>;
  onBlur?: Function;
  style?: any;
}
const { Option } = AutoComplete;

const AutocompleteItem = ({
  name,
  icon,
  disabled,
  options,
  initialValue,
  control,
  placeholder,
  allowClear = false,
  onBlur,
  style
}: AutocompleteItemProps) => {
  const [filteredOptions, setFilteredOptions] = useState<Array<{ id; name }>>([]);

  useEffect(() => {
    if (options) setFilteredOptions(options);
  }, []);

  const handleSearch = (value: string) => {
    if (value.length > 2) {
      const res = (options && options.filter(op => op.name.includes(value) || op.name.toLowerCase().includes(value))) || [];
      setFilteredOptions(res);
    }
  };

  return (
    <div className={`${icon ? 'input-icon' : ''}`}>
      {icon && <span className='icon'>{icon}</span>}
      <Controller
        data-testid={`input-autocomplete-${name}`}
        name={name}
        as={
          <AutoComplete
            style={style}
            onSearch={handleSearch}
            placeholder={placeholder}
            disabled={disabled}
            allowClear={allowClear}
            onBlur={() => onBlur && onBlur()}>
            {filteredOptions &&
              filteredOptions.map(option => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
          </AutoComplete>
        }
        control={control}
        defaultValue={initialValue}
      />
    </div>
  );
};

export default AutocompleteItem;
