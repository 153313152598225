import { ofType } from 'redux-observable';
import { map, concatMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import actions from './actions';
import { getCropwisePlans } from './service';
import { ErrorMsg, notifyErrorOrFallback } from '../../settings/models/Error';

export const handleLoadCropwisePlans = action$ =>
  action$.pipe(
    ofType(actions.LOAD_CROPWISE_PLANS),
    concatMap(() =>
      getCropwisePlans().pipe(
        map((plansPage) => {
          const plans = plansPage?.map(({ id, name }) => ({ id, name }));
          return actions.loadCropwisePlansSuccess(plans);
        }),
        catchError((error: ErrorMsg) => {
          const errorMsg = notifyErrorOrFallback(error, 'Error loading cropwise plans!');
          return of(actions.loadCropwisePlansFailure(errorMsg));
        })
      )
    )
  );
