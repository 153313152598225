import axios from 'axios-observable';
import { CropwisePlansPage, protectorAppId } from './models';
import CONFIG from '../../settings';
import { of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

const cropwisePlansUrl = CONFIG.cropwiseApiUrl;

export const getCropwisePlans = () => {
  return getCropwisePlanPage().pipe(
    concatMap(payload => {
      const isLast = payload?.data?.last;
      const content = payload?.data?.content;
      if (isLast) {
        return of(content);
      }
      return getCropwisePlanPage(payload?.data?.pageable?.next_key).pipe(
        concatMap(nextPayload => {
          return of([...content, ...nextPayload.data.content]);
        })
      );
    }),
    map(plans => plans.flat())
  );
};

const getCropwisePlanPage = (nextKey?: string) => {
  const param = nextKey ? `?last_key=${nextKey}` : '';
  return axios.get<CropwisePlansPage>(`${cropwisePlansUrl}/v2/apps/${protectorAppId}/plans${param}`);
};
