import { PlansPage, UUID } from '../app/basicModels';

export type CropwisePlansPage = PlansPage<CropwisePlan>;

export type CropwisePlansState = {
  isLoading: boolean,
  error: string | null,
  plans: CropwisePlanFlat[],
}

export type CropwisePlanFlat = {
  id: UUID;
  name: string;
}

type CropwisePlan = {
  id: UUID;
  app: UUID;
  quotas: any[];
  created_at: string;
  sv: string;
  accounts: UUID[];
  default_duration: number;
  created_by: string;
  valid_countries: any[];
  name: string;
  entitlements: any[];
  updated_at: string;
  updated_by: UUID;
  default: boolean;
};

export const protectorAppId = '50524f54-4543-544f-5226-434149554249'
