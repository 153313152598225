import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import CONFIG from '../../settings';
import { PageableQuery, UUID } from '../app/basicModels';
import { PhenomenonCategory, PhenomenonCategoryPage } from './models';

const categoryUrl = CONFIG.apiUrl + ':' + CONFIG.demeterPort + '/api/v1/categories';

export const getPhenomenonCategories = (
  _query: PageableQuery,
  companyId: UUID,
  showCanonicals: boolean
): AxiosObservable<PhenomenonCategoryPage> => {
  if (companyId) {
    return axios.get<PhenomenonCategoryPage>(
      `${categoryUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&company_id=${companyId}&show_canonicals=${showCanonicals}`
    );
  } else {
    return axios.get<PhenomenonCategoryPage>(
      `${categoryUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&show_canonicals=${showCanonicals}&show_deleted=false`
    );
  }
};

export const getPhenomenonCategory = (categoryId: UUID): AxiosObservable<PhenomenonCategory> => {
  return axios.get<PhenomenonCategory>(`${categoryUrl}/by-id/${categoryId}`);
};

export const savePhenomenonCategory = (category: PhenomenonCategory, isNew: boolean): AxiosObservable<PhenomenonCategory> => {
  if (isNew) {
    return createPhenomenonCategory(category);
  }
  return updatePhenomenonCategory(category);
};

export const createPhenomenonCategory = (category: any): AxiosObservable<PhenomenonCategory> => {
  return axios.post<PhenomenonCategory>(`${categoryUrl}`, category);
};

export const updatePhenomenonCategory = (category: any): AxiosObservable<PhenomenonCategory> => {
  return axios.put<PhenomenonCategory>(`${categoryUrl}`, category);
};

export const deletePhenomenonCategory = (category: PhenomenonCategory): AxiosObservable<PhenomenonCategory> => {
  return axios.put<PhenomenonCategory>(`${categoryUrl}/remove`, category);
};
