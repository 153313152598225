/* eslint-disable */
/* @ts-ignore */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import options from './options';
import Scrollbars from '../../components/utility/customScrollBar.js';
import Menu from '../../components/uielements/menu';
import IntlMessages from '../../components/utility/intlMessages';
import SidebarWrapper from './sidebar.style';
import appActions from '../../redux/app/actions';
import Logo from '../../components/utility/logo';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import { AppVersion } from '../../components/version/version';
import { AppState } from '../../redux/redux.model';
import { useGetRBACAuthorityUser } from '../../querys/rbac/rbac.query';
import { RBACActions, RBACLevels, RBACPermissionTypesEnum } from '../../querys/rbac/rbac.query.model';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

const { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed } = appActions;
const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};

export interface Props {
  changeCurrent: Function;
  toggleCollapsed: Function;
  toggleOpenDrawer: Function;
  changeOpenKeys: Function;
  url: any;
  app: any;
  height: any;
  currentUser: any;
}
const Sidebar = ({ ...props }: Props) => {
  const pagePermission = useGetRBACAuthorityUser({
    rbacOperations: [
      {
        rbacLevels: RBACLevels.USER,
        rbacActions: RBACActions.METHODOLOGY,
        rbacPermission: [RBACPermissionTypesEnum.READ]
      },
      {
        rbacLevels: RBACLevels.USER,
        rbacActions: RBACActions.FIXED_POINTS,
        rbacPermission: [RBACPermissionTypesEnum.READ]
      },
      {
        rbacLevels: RBACLevels.USER,
        rbacActions: RBACActions.CANONICAL_CROP,
        rbacPermission: [RBACPermissionTypesEnum.READ]
      },
      {
        rbacLevels: RBACLevels.USER,
        rbacActions: RBACActions.ADMIN_DEVICES,
        rbacPermission: [RBACPermissionTypesEnum.READ]
      }
    ]
  });

  const handleClick = e => {
    props.changeCurrent([e.key]);
    if (props.app.view === 'MobileView') {
      setTimeout(() => {
        props.toggleCollapsed();
        props.toggleOpenDrawer();
      }, 100);
    }
  };

  const onOpenChange = newOpenKeys => {
    const { app, changeOpenKeys } = props;
    const latestOpenKey = newOpenKeys.find(key => app.openKeys.indexOf(key) <= -1);
    const latestCloseKey = app.openKeys.find(key => newOpenKeys.indexOf(key) <= -1);
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    changeOpenKeys(nextOpenKeys);
  };

  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2']
    };
    return map[key] || [];
  };

  const getMenuItem = ({ singleOption, submenuStyle, submenuColor, index }) => {
    const { key, label, leftIcon, children } = singleOption;
    const url = stripTrailingSlash(props.url);
    if (children) {
      return (
        <SubMenu
          key={key}
          title={
            <span className='isoMenuHolder' style={submenuColor}>
              {leftIcon}
              <span className='nav-text'>
                <IntlMessages id={label} />
              </span>
            </span>
          }
          data-testid={`menu-item-${index}`}>
          {children.map(child => {
            const linkTo = child.withoutDashboard ? `/${child.key}` : `${url}/${child.key}`;
            return (
              <Menu.Item style={submenuStyle} key={child.key}>
                <Link style={submenuColor} to={linkTo}>
                  <IntlMessages id={child.label} />
                </Link>
              </Menu.Item>
            );
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key} data-testid={`menu-item-${index}`}>
        <Link to={`${url}/${key}`}>
          <span className='isoMenuHolder' style={submenuColor}>
            {leftIcon}
            <span className='nav-text'>
              <IntlMessages id={label} />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const { app, toggleOpenDrawer, height, currentUser } = props;
  const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
  const { openDrawer } = app;
  const mode = collapsed === true ? 'vertical' : 'inline';

  const flags = useSelector((state: AppState) => state.App.systemFlags);
  const isYieldEstimationEnabled = flags.P40_41997_YIELD_ESTIMATION_SETUP;

  const enableServiceProviderPage = app.systemFlags.enableServiceProvidersPage;
  const filteredOptions = options.filter(o => {
    if (!enableServiceProviderPage && o.key === 'service-providers') {
      return false;
    }
    if (!isYieldEstimationEnabled && o.key === 'seeds-setup') {
      return false;
    }
    return true;
  });
  const onMouseEnter = event => {
    if (openDrawer === false) {
      toggleOpenDrawer();
    }
    return;
  };
  const onMouseLeave = () => {
    if (openDrawer === true) {
      toggleOpenDrawer();
    }
    return;
  };
  const customizedTheme = themes[themeConfig.theme];
  const styling = {
    backgroundColor: customizedTheme.backgroundColor
  };
  const submenuStyle = {
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: customizedTheme.textColor
  };
  const submenuColor = {
    color: customizedTheme.textColor
  };

  return (
    <SidebarWrapper>
      <Sider
        trigger={null}
        collapsible={true}
        collapsed={collapsed}
        width={240}
        className='isomorphicSidebar'
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={styling}>
        <Logo collapsed={collapsed} />
        <Scrollbars style={{ height: height - 70 }}>
          <Menu
            onClick={handleClick}
            theme='dark'
            className='isoDashboardMenu'
            mode={mode}
            openKeys={collapsed ? [] : app.openKeys}
            selectedKeys={app.current}
            onOpenChange={onOpenChange}
            data-testid={`sidebar-menu`}>
            {currentUser &&
              filteredOptions.map((singleOption, index) =>
                singleOption.canAccessPermission
                  ? !!pagePermission?.[singleOption.canAccessPermission.toLowerCase()]?.length &&
                    getMenuItem({
                      submenuStyle,
                      submenuColor,
                      singleOption,
                      index
                    })
                  : getMenuItem({
                      submenuStyle,
                      submenuColor,
                      singleOption,
                      index
                    })
              )}
          </Menu>
        </Scrollbars>
        <AppVersion />
      </Sider>
    </SidebarWrapper>
  );
};

export default connect(
  (state: AppState) => ({
    app: state.App,
    height: state.App.height,
    currentUser: state.User.currentUser
  }),
  { toggleOpenDrawer, changeOpenKeys, changeCurrent, toggleCollapsed }
)(Sidebar);
