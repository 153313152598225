import React from 'react';

const YieldEstimationIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.77214 13.6838C4.90825 13.2754 5.29039 13 5.72082 13H17.816C18.2284 13 18.5985 13.2532 18.748 13.6376L21.4702 20.6376C21.7252 21.2931 21.2416 22 20.5382 22H3.38749C2.70493 22 2.22296 21.3313 2.4388 20.6838L4.77214 13.6838ZM17.0001 15H6.50006L5.00006 20H19.0001L17.0001 15Z'
      fill='#707374'
    />
    <path d='M11.0001 17.3002H13.0001V10.1463L11.918 10.1461L11.0001 10.0469V17.3002Z' fill='#707374' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.96265 5.05161C9.04653 5.14825 10.0727 5.4754 10.6483 6.1941C11.3214 7.03453 11.6609 8.21217 11.8169 9.26879C11.8653 9.59702 11.8943 9.90153 11.9113 10.1637C11.7271 10.1589 11.5216 10.1455 11.3026 10.1182C10.4319 10.0096 9.42394 9.69203 8.63588 8.90405C7.83063 8.09887 7.38726 6.9389 7.15502 5.89323C7.08454 5.5759 7.03588 5.2799 7.00233 5.02299C7.28631 5.01569 7.6152 5.02063 7.96265 5.05161ZM11.9235 10.1573C11.9715 10.0361 12.026 9.90847 12.0875 9.77667C12.4746 8.94719 13.1121 8.01158 14.1333 7.39885C15.1105 6.81254 16.0888 6.80511 16.8602 6.95936C17.0231 6.99193 17.1748 7.03144 17.3126 7.07345C17.2243 7.25688 17.1203 7.45851 17.0008 7.66746C16.5306 8.48939 15.9028 9.27776 15.1707 9.6448C14.3798 10.0412 13.3173 10.1657 12.3685 10.1659C12.213 10.166 12.0638 10.1627 11.9235 10.1573Z'
      fill='#707374'
    />
  </svg>
);

export default YieldEstimationIcon;
