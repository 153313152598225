import axios from 'axios-observable';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PageableQuery, UUID } from '../app/basicModels';
import { PhenomenonPage, Phenomenon, PhenomenonSimple, ClonedCharacteristicsIndicators, CloneCharacteristicsIndicatorsDTO } from './models';
import CONFIG from '../../settings';
import { CommandRequest } from '../../helpers/request/models';

const phenomenonUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/phenomenons`;
const phenomenaApiUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/api/v1/phenomena`;
const phenomenaUrl = `${CONFIG.apiUrl}:${CONFIG.demeterPort}/v1/phenomena`;

export const getPhenomenons = (_query: PageableQuery, companyId: UUID, showCanonicals: boolean): AxiosObservable<PhenomenonPage> => {
  if (companyId) {
    return axios.get<PhenomenonPage>(
      `${phenomenonUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&company_id=${companyId}&show_canonicals=${showCanonicals}`
    );
  }
  return axios.get<PhenomenonPage>(
    `${phenomenonUrl}?page=${_query.pageable.page}&size=${_query.pageable.size}&show_canonicals=${showCanonicals}&shallow=true`
  );
};

export const getPhenomenon = (phenomenonId: UUID): AxiosObservable<Phenomenon> => {
  return axios.get<Phenomenon>(`${phenomenonUrl}/by-id/${phenomenonId}`, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate'
    }
  });
};

export const getPhenomenonsSimple = (ids: UUID[]): AxiosObservable<PhenomenonSimple> => {
  return axios.post<PhenomenonSimple>(`${phenomenaApiUrl}/ids/simple`, { ids });
};

export const savePhenomenon = (category: Phenomenon, isNew: boolean): AxiosObservable<Phenomenon> => {
  if (isNew) {
    return createPhenomenon(category);
  }
  return updatePhenomenon(category);
};

export const createPhenomenon = (category: any): AxiosObservable<Phenomenon> => {
  return axios.post<Phenomenon>(`${phenomenonUrl}`, category);
};

export const updatePhenomenon = (category: any): AxiosObservable<Phenomenon> => {
  return axios.put<Phenomenon>(`${phenomenonUrl}`, category);
};

export const deletePhenomenon = (category: Phenomenon): AxiosObservable<Phenomenon> => {
  return axios.put<Phenomenon>(`${phenomenonUrl}/remove`, category);
};

export const clonePhenomenon = (phenomenonId: UUID, companyId: UUID, enableCottonGrowth?: boolean): AxiosObservable<Phenomenon> => {
  return enableCottonGrowth
    ? axios.post(`${phenomenaUrl}/${phenomenonId}/clone-to-company/${companyId}`)
    : axios.post(`${phenomenonUrl}/${phenomenonId}/clone-to-company/${companyId}`);
};

export const cloneCharacteristicsIndicators = (
  itemsToClone: CommandRequest<CloneCharacteristicsIndicatorsDTO>
): AxiosObservable<ClonedCharacteristicsIndicators> => {
  return axios.post<ClonedCharacteristicsIndicators>(`${phenomenaApiUrl}/clone-entities`, itemsToClone);
};
